/*
 * @Author: alex
 * @Date: 2021-12-29 16:18:51
 * @LastEditors: alex
 * @LastEditTime: 2021-12-29 16:18:51
 */

var OSS =  require('ali-oss')


var aliOssClient = new OSS({
    region: "oss-cn-guangzhou.aliyuncs.com",
    accessKeyId: "LTAI5tEtnZJkS14Arx7mBWD8",
    accessKeySecret: "mdq1IRtATCtMnWDu4Nl91gjCdVYFzc",
    bucket: "boyaminge"
});

export default aliOssClient